import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_FILEUPLOAD, FORM_INPUT, FORM_TEXTAREA, FORM_TOGGLE, TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import 'i18n';
import form_helper from 'libs/form-lib';

import CMP_OID4VP_PRESENTATION_REQUEST from 'components/cmp_oid4vp_presentation_request/cmp_oid4vp_presentation_request';
import MDL_CERTIFICATE_PREVIEW from './mdl_certificate_preview/mdl_certificate_preview';


export default function CRD_DETAILS({ credential, settings, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_updated_credential, set_updated_credential ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_preview_open, set_preview_open ] = useState(false);
    const [ var_vprequest_open, set_vprequest_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_fileupload_status, set_fileupload_status ] = useState('');



    //  event listeners ------------------------------------------------------------------------------------------------


    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            update_credential(var_updated_credential);
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_save_error('There was a problem saving.  Please try again later');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    useEffect(() => {
        if (!var_preview_open && var_focuselement?.id === 'crd_creddetails_details') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    },[var_preview_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function update_credential(credential) {
        try {
            await API_put_org_credential_details(credential);
            onChange();
            set_mode('VIEW');
        } catch (e) {
            set_save_error('There was a problem saving.  Please try again later');
            console.log(e);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_org_credential_details(credential) {
        return API.put('credentials', '/put-org-credential-details/' + credential.id,
            { body: {
                status: credential.status,
                name: credential.name,
                code: credential.code,
                description: credential.description,
                skills: credential.skills,
                imagefile: credential.imagefile,
                submittedfilename: credential.submittedfilename
            }});
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_updated_credential({...credential});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_errors([]);
        set_save_error(null);
        set_mode('VIEW');
    }

    function onClick_save() {
        if (var_processing) return;

        let updated_credential = {...var_updated_credential};
        let errors = [];
        //validation
        if (!form_helper.validate_required_string(updated_credential.name)) {
            errors.push({ property: 'name', description: t('Credential name is required') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(updated_credential, 'name');
        form_helper.cleanse_string_property(updated_credential, 'code');
        form_helper.cleanse_string_property(updated_credential, 'description');
        form_helper.cleanse_string_property(updated_credential, 'skills');

        set_updated_credential(updated_credential);

        // save
        set_processing(true);
        try {
            if (var_fileupload_status === 'UPLOAD READY') {
                set_fileupload_status('START UPLOAD');
            } else {
                update_credential(updated_credential);
            }
        } catch(e) {
            set_save_error('There was a problem saving.  Please try again later');
        }
    }

    function onChange_input(event, { name, value }) {
        let credential = {...var_updated_credential};
        credential[name] = value;
        set_updated_credential(credential);
        set_errors(var_errors.filter(error => error.property !== name));
    }

    function onChange_file(details) {
        set_updated_credential({...var_updated_credential, imagefile: details.filename, submittedfilename: details.display_name});
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_creddetails_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_creddetail_details'>{t('Credential details')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                    {var_mode === 'VIEW' && settings.verifiable_credential === 'YES' && credential.display_present_to_midy === 'YES' &&
                        <Button className='secondary' onClick={() => set_vprequest_open(true)}>{t('Request Presentation from Midy')}</Button>
                    }
                </div>
            </div>

            {credential && credential.has_prequalifier === 'YES' && credential.prequalifier_count === 0 &&
                <Message warning
                    icon={<Icon name='warning' className='icon' alt={t('warning icon')} />}
                    header={t('Pre-qualifications must be added to this credential before it can be published.')}
                />
            }


            {var_mode === 'VIEW' ?
                <>
                    <div className='card__content'>
                        {credential &&
                        <>
                        <FORM_DETAILSGROUP label={t('Status')} value={credential.status === 'ACTIVE' ? t('Published') : t('Unpublished')} type='badge' badgecolor={credential.status === 'ACTIVE' ? 'badge--green' : 'badge--gray'}/>
                        <FORM_DETAILSGROUP label={t('Name')} value={credential.name} />
                        <FORM_DETAILSGROUP label={t('Credential code')} value={credential.code} />
                        <FORM_DETAILSGROUP label={t('Description')} value={credential.description} />
                        <FORM_DETAILSGROUP label={t('Skills')} value={credential.skills} />
                        <FORM_DETAILSGROUP label={t('Credential image')} value={credential.imagefile} type='logo'
                            logo_details={{ targetid: credential.id, classification: 'course', filename: credential.imagefile, credivera_on_missing: false, credivera_on_error: false, message_on_virus_scan: true, alt: t('course logo') }}
                        />
                        {credential.credits_required_for_issuance === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Credit tracking')} value={credential.credits_required_for_issuance === 'YES' ? t('Yes') : t('No')} />
                        }
                        <FORM_DETAILSGROUP label={t('Credential certificate')} value={() => {set_focuselement(document.getElementById('crd_creddetails_details')); set_preview_open(true)}} type='internallink' linktext={t('Preview credential certificate')} />
                        </>
                        }
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>

                    <MDL_CERTIFICATE_PREVIEW is_open={var_preview_open} onClose={() => set_preview_open(false)} credential={credential} />
                    <CMP_OID4VP_PRESENTATION_REQUEST display={var_vprequest_open} credential_id={credential?.id} onClose={() => set_vprequest_open(false)} />

                </>
                : var_mode === 'EDIT' ?
                    <>
                        <Form className='card__content' id='form_creddetail_details' aria-labelledby='hdr_creddetail_details'>

                            {var_save_error &&
                                <Message error header={t(var_save_error)}/>
                            }

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <FORM_TOGGLE
                                label={t('Status')}
                                value={var_updated_credential.status}
                                options={[{ value: 'ACTIVE', text: t('Published') }, { value: 'PENDING',text: t('Unpublished')} ]}
                                onChange={(value) => onChange_input(null, { name: 'status', value })}
                                disabled={(credential.has_prequalifier === 'YES' && credential.prequalifier_count === 0) || (credential.credits_required_for_issuance === 'YES' && credential.credential_credit_count === 0)}
                                description={
                                    (credential.has_prequalifier === 'YES' && credential.prequalifier_count === 0)
                                    ? t('This credential will be “Unpublished” until qualification requirements have been added.')
                                    : (credential.credits_required_for_issuance === 'YES' && credential.credential_credit_count === 0)
                                    ? t('This credential will be “Unpublished” until credit tasks have been added.')
                                    : t('A “Published” credential can be issued immediately. An “Unpublished” credential can not be issued until its status is updated to published.')
                                }
                            />

                            <FORM_INPUT
                                property='name'
                                label={t('Name')}
                                value={var_updated_credential.name}
                                onChange={onChange_input}
                                placeholder={t('Credential name')}
                                maxLength={255}
                                disabled={false}
                                errors={var_errors}
                                description=''
                            />

                            <FORM_INPUT
                                property='code'
                                label={t('Credential code (optional)')}
                                value={var_updated_credential.code}
                                onChange={onChange_input}
                                placeholder={t('Credential code')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                                description={t('Specified by an organization\'s credential catalogue; the credential code is a method to identify the credential\'s topic and to distinguish it from other credentials in that subject area.')}
                            />

                            <FORM_TEXTAREA
                                property='description'
                                label={t('Description (optional)')}
                                value={var_updated_credential.description}
                                onChange={onChange_input}
                                placeholder={t('Description')}
                                maxLength={1000}
                                disabled={false}
                                errors={var_errors}
                                description={t('Describe the qualification, competence, or authority issued to an individual holding this credential. This description will be displayed on the public credential certificate.')}
                            />

                            <FORM_TEXTAREA
                                property='skills'
                                label={t('Skills (optional)')}
                                value={var_updated_credential.skills}
                                onChange={onChange_input}
                                placeholder={t('Skills')}
                                maxLength={500}
                                disabled={false}
                                errors={var_errors}
                                description={t('The applicable skills developed by an individual who has earned this credential. The skills will be displayed on the public credential certificate.')}
                            />

                            <FORM_FILEUPLOAD
                                label={t('Credential image (optional)')}
                                property='image'
                                onChange={onChange_file}
                                single_or_multiple='SINGLE'
                                var_fileupload_status={var_fileupload_status}
                                set_fileupload_status={set_fileupload_status}
                                uploaddirectory='courses'
                                max_size={10}
                                allowed_fileextensions={['png', 'jpg', 'jpeg']}
                                description={t('The image will be displayed on the credential certificate when a description or skill has been provided for the credential.')}
                                instructions={t('Only JPG and PNG file types are accepted and should be a minimum 90x90 pixels.')}
                                errors={var_errors}
                                logo_details={credential.imagefile
                                    ? { targetid: credential.id, classification: 'course', filename: credential.imagefile, credivera_on_missing: false, credivera_on_error: false, message_on_virus_scan: true, alt: t('course logo') }
                                    : null}
                            />

                            {credential.credits_required_for_issuance === 'YES' && 
                                <FORM_TOGGLE
                                    label={t('Credit tracking')}
                                    value={credential.credits_required_for_issuance ?? 'NO'}
                                    options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                    description={t('Enable the tracking of completed credits toward a predefined goal.')}
                                    disabled={true}
                                />
                            }

                            {credential.credits_required_for_issuance === 'YES' &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Individuals can edit and submit credit updates.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div id='field__credit_value' className='detailsgroup__subgroup__content__item'>
                                            <TOGGLE
                                                value={credential.allow_individual_to_update_credit_tracking ?? 'NO'}
                                                options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                                onChange={(value) => onChange_input(null, { name: 'allow_individual_to_update_credit_tracking', value })}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                        </Form>

                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                            <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                        </div>

                    </>

                    : null

                    }

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}