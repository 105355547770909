import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_SELECT, FORM_TOGGLE, INPUT, SELECT, TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import 'i18n';
import form_helper from 'libs/form-lib';
import './crd_settings.css';



export default function CRD_SETTINGS({ credential_settings, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_updated_settings, set_updated_settings ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_had_proof_type, set_had_proof_type ] = useState(false);

    const [ var_categories, set_categories ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_categories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function update_settings(settings) {
        set_processing(true);
        try {
            await API_put_org_credential_settings(settings);
            set_mode('VIEW');
            onChange && onChange();
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }

    async function populate_categories() {
        let results = (await API_get_credential_category_list()).filter(item => item.id !== 'ASSET_INSPECTION_REPORT');
        let transformed = results.map(item => ({ value: item.id, text: t(item.id) }));
        transformed = transformed.sort((a, b) => a.text - b.text);
        set_categories(transformed);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_org_credential_settings(credential) {
        return API.put('credentials', '/put-org-credential-settings/' + credential_settings.id,
            { body: {
                credential_category_id: credential.credential_category_id,
                verifiable_credential: credential.verifiable_credential,
                proof_type: (!var_had_proof_type || credential.status !== 'ACTIVE') && credential.verifiable_credential === 'NO' ? null : credential.proof_type,
                expires: credential.expires,
                expires_unit: credential.expires_unit,
                expires_amount: credential.expires_amount,
                restrict_claims: credential.restrict_claims,
                auto_verify: credential.auto_verify,
                issuedrequired: credential.issuedrequired,
                credential_number_required: credential.credential_number_required,
                credential_number_label: credential.credential_number_label,
                credit_value: credential.has_credit_value === 'YES' ? Number(credential.credit_value) : null,
                has_credit_value: credential.has_credit_value
            }});
    }

    function API_get_credential_category_list() {
        return API.get('credentials', '/get-credential-category-list');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_updated_settings({...credential_settings});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_errors([]);
        set_save_error(null);
        set_mode('VIEW');
    }

    function onClick_save() {
        if (var_processing) return;

        let settings = {...var_updated_settings};
        let errors = [];
        //validation
        if (!form_helper.validate_required_string(settings.credential_category_id)) {
            errors.push({ property: 'credential_category_id', description: t('Please add a credential category.') });
        }
        if (settings.expires === 'YES' && !form_helper.validate_positive_number(settings.expires_amount)) {
            errors.push({ property: 'expires_amount', description: t('Please add a valid expiration value.') });
        }
        if (settings.expires === 'YES' && !form_helper.validate_required_string(settings.expires_unit)) {
            errors.push({ property: 'expires_unit', description: t('Please add an expiration duration.') });
        }
        if (settings.credential_number_required === 'YES' && !form_helper.validate_required_string(settings.credential_number_label)) {
            errors.push({ property: 'credential_number_label', description: t('Please add a valid credential label.') });
        }
        if (settings.has_credit_value === 'YES' && !form_helper.validate_positive_number(settings.credit_value)) {
            errors.push({ property: 'credit_value', description: t('Please add a valid credential value.') });
        }
        set_errors(errors);
        if (errors.length > 0) return;
        // cleanse properties
        form_helper.cleanse_string_property(settings, 'expires_amount');
        form_helper.cleanse_string_property(settings, 'credential_number_label');
        form_helper.cleanse_decimal_property(settings, 'credit_value');

        set_updated_settings(settings);
        update_settings(settings);
    }

    function onChange_input(event, { name, value }) {
        let settings = {...var_updated_settings};
        switch (name) {
            case 'expires_amount':
                value = value.replace(/\D/,'');
                break;
            case 'credit_value':
                value = form_helper.format_decimal_string(value, 3, 2);
                break;
            case 'verifiable_credential':
                settings.proof_type = value === 'YES' ? 'JWT': null;
                break;
            default:
                break;
        }
        settings[name] = value;
        set_updated_settings(settings);
        set_errors(var_errors.filter(error => event && error.property !== event.target.name));
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_creddetails_settings'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_creddetail_settings'>{t('Credential settings')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_mode === 'VIEW' ?
                <>
                    <div className='card__content'>
                        {credential_settings.has_wallet_feature === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Type')} value={credential_settings.indv_or_org === 'INDV' ? t('Individual') : t('Organization')} />
                        }
                        <FORM_DETAILSGROUP label={t('Category')} value={t(credential_settings.credential_category_id)} />
                        {credential_settings.has_verifiable_credential_feature === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Verifiable credential')} value={credential_settings.verifiable_credential ? t(credential_settings.verifiable_credential.substring(0, 1) + credential_settings.verifiable_credential.substring(1).toLowerCase()) : ''} />
                        }
                        {credential_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' &&
                            <FORM_DETAILSGROUP label={t('Issued date')} value={credential_settings.issuedrequired === 'YES' ? t('Required') : t('Not required')}/>
                        }
                        {credential_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' &&
                            <FORM_DETAILSGROUP label={t('Expires')} value={credential_settings.expires ? t(credential_settings.expires.substring(0, 1) + credential_settings.expires.substring(1).toLowerCase()) : ''} />
                        }
                        <FORM_DETAILSGROUP label={t('Restrict claims')} value={credential_settings.restrict_claims ? t(credential_settings.restrict_claims.substring(0, 1) + credential_settings.restrict_claims.substring(1).toLowerCase()) : ''}/>
                        {credential_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' &&
                            <FORM_DETAILSGROUP label={t('Auto-verify')} value={credential_settings.auto_verify ? t(credential_settings.auto_verify.substring(0, 1) + credential_settings.auto_verify.substring(1).toLowerCase()) : ''}/>
                        }
                        {credential_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' &&
                            <FORM_DETAILSGROUP label={t('Additional field')} value={credential_settings.credential_number_required === 'YES' ? t('Required') : t('Not required')} />
                        }
                        <FORM_DETAILSGROUP label={t('Credit value')} value={credential_settings.has_credit_value === 'YES' ? String(credential_settings.credit_value) : t('No')} />
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>

                </>
                : var_mode === 'EDIT' ?
                    <>
                        <Form className='card__content' id='form_creddetail_settings' aria-labelledby='hdr_creddetail_settings'>

                            {var_save_error &&
                                <Message error header={var_save_error}/>
                            }

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            {credential_settings.has_wallet_feature === 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Type')}
                                    disabled={true}
                                    value={var_updated_settings.indv_or_org}
                                    options={[{ value: 'INDV', text: t('Individual') }, { value: 'ORG', text: t('Organization') }]}
                                    onChange={null}
                                    description={t('An “Individual” credential can be issued to, and held by, a person. An “Organization” credential can be issued to, and held by, a business. The credential type can not be changed after it has been set.')}
                                />
                            }

                            <FORM_SELECT
                                property='credential_category_id'
                                label={t('Category')}
                                value={var_updated_settings.credential_category_id || ''}
                                onChange={onChange_input}
                                placeholder={t('Category')}
                                options={var_categories}
                                disabled={false}
                                errors={var_errors}
                                description={t('Categories help sort credentials in an individual’s wallet and control how they can be shared.')}
                            />

                            {credential_settings.has_verifiable_credential_feature === 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Verifiable credential')}
                                    value={var_updated_settings.verifiable_credential}
                                    options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'verifiable_credential', value })}
                                    description={t('This credential is available as a verifiable credential.')}
                                />
                            }

                            {var_updated_settings.verifiable_credential === 'YES' && credential_settings.has_proof_type === 'YES' &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Select the format of the linked data proof for this verifiable credential. Once the credential is published the selected format cannot be changed.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div className='detailsgroup__subgroup__content__item'>
                                            <TOGGLE
                                                value={var_updated_settings.proof_type ? var_updated_settings.proof_type : var_updated_settings.proof_type = 'JWT'}
                                                options={[{ value: 'JWT', text: t('JWT') }, { value: 'JSONLD', text: t('JSON-LD') }]}
                                                onChange={(value) => onChange_input(null, { name: 'proof_type', value })}
                                                disabled={var_had_proof_type && credential_settings.status === 'ACTIVE'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {var_updated_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Issued date')}
                                    value={var_updated_settings.issuedrequired}
                                    options={[{ value: 'YES', text: t('Required') }, { value: 'NO', text: t('Not required') }]}
                                    onChange={(value) => onChange_input(null, { name: 'issuedrequired', value })}
                                    description={t('When a claim is made for this credential capture the date it was issued to the individual.')}
                                />
                            }

                            {credential_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Expires')}
                                    value={var_updated_settings.expires}
                                    options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'expires', value })}
                                    message_type={(var_updated_settings.expires === 'YES' && var_updated_settings.issuedrequired !== 'YES') ? 'WARNING' : null}
                                    message_text={t('When specifying an expiration date, an issued date is typically required. Please review your issued date settings.')}
                                    description={t('This credential expires.')}
                                />
                            }

                            {credential_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' && var_updated_settings.expires === 'YES' &&
                                <div id='subgroup_expiry' className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Specify the timeframe for expiry.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div className='detailsgroup__subgroup__content__item'>
                                            <div className='text--sm-medium'>{t('Value')}</div>
                                            <INPUT
                                                property='expires_amount'
                                                value={var_updated_settings.expires_amount}
                                                onChange={onChange_input}
                                                placeholder={'0'}
                                                maxLength={2}
                                                errors={var_errors}
                                            />
                                        </div>
                                        <div className='detailsgroup__subgroup__content__item'>
                                            <div className='text--sm-medium'>{t('Duration')}</div>
                                            <SELECT
                                                property='expires_unit'
                                                value={var_updated_settings.expires_unit}
                                                onChange={onChange_input}
                                                placeholder={t('Duration')}
                                                options={[{ value: 'day', text: t('Days') }, { value: 'month', text: t('Months') }, { value: 'year', text: t('Years') }]}
                                                errors={var_errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <FORM_TOGGLE
                                label={t('Restrict claims')}
                                value={var_updated_settings.restrict_claims}
                                options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                onChange={(value) => onChange_input(null, { name: 'restrict_claims', value })}
                                description={t('To obtain this credential, individuals cannot claim it directly. Instead, an Admin must add it to the holder\'s account.')}
                            />
                            {var_updated_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Auto-verify')}
                                    value={var_updated_settings.auto_verify}
                                    options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'auto_verify', value })}
                                    description={t('This credential is automatically verified when claimed.')}
                                />
                            }

                            {credential_settings.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Additional field')}
                                    value={var_updated_settings.credential_number_required}
                                    options={[{ value: 'YES', text: t('Required') }, { value: 'NO', text: t('Not required') }]}
                                    onChange={(value) => onChange_input(null, { name: 'credential_number_required', value })}
                                    description={t('Add a field to capture additional information when a claim is made for this credential e.g. credential number, course ID, student ID, etc.')}
                                />
                            }

                            {var_updated_settings.credential_number_required === 'YES' &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Specify the label to be displayed for this field. All values will be stored as text.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div id='id__fieldlabel' className='detailsgroup__subgroup__content__item'>
                                            <div className='text--sm-medium'>{t('Field label')}</div>
                                            <INPUT
                                                property='credential_number_label'
                                                value={var_updated_settings.credential_number_label || ''}
                                                onChange={onChange_input}
                                                placeholder={t('Field label')}
                                                maxLength={100}
                                                errors={var_errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <FORM_TOGGLE
                                label={t('Credit value')}
                                value={var_updated_settings.has_credit_value}
                                options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                onChange={(value) => onChange_input(null, { name: 'has_credit_value', value })}
                                description={t('Specify a credit value for this credential.')}
                            />

                            {var_updated_settings.has_credit_value === 'YES' &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('The credit value will be applied when this credential is used as a pre-qualifier for a credit-based stacked credential.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div id='field__credit_value' className='detailsgroup__subgroup__content__item'>
                                            <div className='text--sm-medium'>{t('Credit value')}</div>
                                            <INPUT
                                                property='credit_value'
                                                value={var_updated_settings.credit_value || ''}
                                                placeholder='1'
                                                onChange={onChange_input}
                                                errors={var_errors}
                                                disable_icon={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                        </Form>

                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                            <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                        </div>


                    </>

                    : null

                    }

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}