const form_helper = {
    cleanse_string(thestring) {
        return (!thestring || thestring.trim().length === 0) ? null : thestring.trim();
    },
    cleanse_string_property: function(the_object, property_name) {
        the_object[property_name] = form_helper.cleanse_string(the_object[property_name]);
    },
    cleanse_decimal_property: function(the_object, property_name) {
        the_object[property_name] = [null, undefined].includes(the_object[property_name]) ? null : isNaN(Number(the_object[property_name])) ? null : Number(the_object[property_name]);
    },
    format_decimal_string(input_string, max_whole_number_digits, max_decimal_places) {
        let scrubbed_input = input_string.replace(/[^0-9.]/g, ''); // remove all non-digits excluding '.'
        let [whole_number, fraction] = scrubbed_input.split('.');

        if (whole_number) whole_number = whole_number.slice(0, max_whole_number_digits);
        if (fraction) fraction = fraction.slice(0, max_decimal_places);

        // If working with integer
        if (!scrubbed_input.includes('.')) {
            return whole_number;
        // If working with fraction
        } else {
            return `${whole_number}.${fraction}`;
        }
    },
    // validations
    validate_required_string: function(value) {
        return !!value && value.trim().length > 0;
    },
    validate_number: function(value, is_required, min_value, max_value) {
        if (['null', 'undefined', ''].includes(String(value).trim())) {
            return !is_required;
        }
        let the_number;
        if (typeof value === 'string') {
            the_number = Number(value.trim());
        } else if (typeof value === 'number') {
            the_number = value;
        } else {
            return false;
        }
        if (isNaN(the_number)) return false;
        if (![null, undefined].includes(min_value) && the_number < min_value) return false;
        if (![null, undefined].includes(max_value) && the_number > max_value) return false;
        return true;
    },
    validate_positive_number: function(value) {
        if (!value) {
            return false;
        }
        if (typeof value === 'string') {
            return Number(value.trim()) > 0;
        } else if (typeof value === 'number') {
            return value > 0;
        } else {
            return false
        }
    },
    validate_email_address: function(value, allow_displayname = false) {
        // email regular expression from: http://emailregex.com
        let backtick = '`';
        let email_pattern = String.raw`(?:[a-z0-9!#$%&'*+/=?^_${backtick}{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_${backtick}{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])`;
        // check if the value is just an email
        let email_only_check = new RegExp(`^${email_pattern}$`, 'i').test(value.trim());
        if (!allow_displayname || email_only_check) return email_only_check;
        // not sure on the allowed characters for the display name
        // so I just made it that it must start with a non-space character, followed by 0 or more other characters of any type
        return new RegExp(String.raw`^\S.*?\s<${email_pattern}>$`, 'i').test(value.trim());
    },
    validate_date: function(value, minimum, maximum) {
        let floor_date = new Date('1900-01-01T00:00:00');
        let ceiling_date = new Date('2100-01-01T00:00:00');
        minimum = (minimum && minimum.getTime() > floor_date.getTime()) ? minimum : floor_date;
        maximum = (maximum && maximum.getTime() < ceiling_date.getTime()) ? maximum : ceiling_date;
        return {
            minimum,
            maximum,
            is_less_than_minimum: value.getTime() < minimum.getTime(),
            is_greater_than_maximum: value.getTime() > maximum.getTime()
        }
    }
};

export default form_helper;