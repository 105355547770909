import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import { Button, Message, Form } from 'semantic-ui-react';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_SELECT, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';



export default function CRD_AUTO_ADD_CREDENTIAL_CREDIT_TRACKING({ qr_data }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const { t } = useTranslation('public');

    const [ var_data, set_data ] = useState(null);
    const [ var_processing, set_processing ] = useState(true);
    const [ var_save_error, set_save_error ] = useState();


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_data();
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_data() {
        set_data(await API_get_credential_credit_tracking_qr_code());
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_credit_tracking_qr_code() {
        return API.get('credentials', '/get-credential-credit-tracking-qr-code/' + qr_data.id, {
            queryStringParameters: { tz: datelib.timezone }
        });
    }

    function API_post_credential_credit_tracking_qr_code() {
        return API.post('credentials', '/post-credential-credit-tracking-qr-code/' + qr_data.id, {
            queryStringParameters: { tz: datelib.timezone }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_confirm() {
        if (var_processing) return;
        set_processing(true);
        try {
            let individual_credential_id = await API_post_credential_credit_tracking_qr_code();
            navigate('/credentials/all/' + individual_credential_id);
        } catch {
            set_save_error('An unexpected error occurred. Please try again.');
        }
        set_processing(false);
    }

    function onClick_dashboard() {
        navigate('/dashboard')
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='page' id='qr_code'>
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('Course credits')}</h1>
                </div>

                <div className='card rounded-lg shadow-sm' id='crd__auto_add_credential_credit_tracking' tabIndex='0' style={{ minHeight: '15rem' }}>
                    {var_save_error &&
                        <Message error
                            icon={<Icon name='error' className='icon' />}
                            header={var_save_error}
                        />
                    }

                    <div>
                        {var_data && render_data()}
                    </div>

                    <div className='card__footer'>
                        {(!!var_data && !var_save_error) ?
                            <>
                                <Button className='primary' onClick={onClick_confirm} style={{ maxWidth: '12rem' }}>{t('Continue')}</Button>
                                <Button className='secondary' onClick={onClick_dashboard} style={{ maxWidth: '12rem' }}>{t('Cancel')}</Button>
                            </>
                            :
                            <Button className='primary' onClick={onClick_dashboard} style={{ maxWidth: '12rem' }}>{t('Dashboard')}</Button>
                        }
                    </div>

                    <Processing display={var_processing} processingtext={t('Processing')} />
                </div>
            </div>
          </div>
    );

    function render_data() {
        return (
            <Form className='card__content'>
                <div className='text--sm-regular'>
                    {var_data.individual_credential_exists === 'YES'
                        ? t('Confirm that you are claiming credits for the following credential')
                        : t('Confirm that you are adding the following credential to your wallet with credits')
                    }
                </div>

                <FORM_INPUT label={t('Issuer')} value={var_data.issuer_name} disabled={true} />

                <FORM_INPUT label={t('Credential')} value={var_data.credential_name} disabled={true} />

                {var_data.tasks.map(item =>
                    <FORM_INPUT
                        key={item.id}
                        label={!!item.parent_description
                                ? `${item.parent_description} - ${item.description}`
                                : item.description
                            }
                        value={`${datelib.epoch_to_date_string(item.activity_date)} - ${item.credits} ${item.credits === 1 ? t('credit') : t('credits')}`}
                        disabled={true}
                    />
                )}
            </Form>
        )
    }

};
