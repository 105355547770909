import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';
import datelib from 'libs/date-lib';

import CMP_CREDIT_ACTIVITY from 'components/cmp_credit_activity/cmp_credit_activity';

function CMP_CREDIT_ACTIVITY_STATUS({ is_open, activity, onChange, onMessage, onOpen, onClose, can_edit = false }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_statuspopup_value, set_statuspopup_value ] = useState(null);
    const [ var_cmp_credit_activity_open, set_cmp_credit_activity_open ] = useState(false);

    const [ var_processing, set_processing ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);

    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_status_badge_button, set_status_badge_button ] = useState(null);
    const [ var_popup, set_popup ] = useState(null);

    const POPUP_ID = `cmp_credit_activity_status_${activity?.id}`;

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_status_badge_button(document.getElementById(`btn_${POPUP_ID}`));
    }, [POPUP_ID]);

    useEffect(() => {
        if (is_open) {
            set_popup(document.querySelector(`#${POPUP_ID}`));
            set_errors([]);
            set_statuspopup_value(activity.status);
        } else if (var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('.badge__popup, form, input[type="checkbox"]:not([disabled]), select:not([disabled]), button:not([disabled]), textarea:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function update_status() {
        set_processing(true);
        onMessage && onMessage(null, null);
        if (activity.status !== var_statuspopup_value) {
            try {
                await API_put_credit_activity_status();
                onChange && onChange(var_focuselement?.id);
            } catch (e) {
                onMessage && onMessage('ERROR', t('There was a problem saving.  Please try again later'));
                console.log(e);
            }
            onClose();
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_credit_activity_status(status) {
        return API.put('credentials', '/put-credit-activity-status/' + activity.id, {
                queryStringParameters: { tz: datelib.timezone },
                body: {
                    status: var_statuspopup_value
                },
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_status(value) {
        set_errors([]);
        set_statuspopup_value(value);
    }

    async function onClick_update_status() {
        if (var_processing) return;
        // validation
        let errors = [];
        if (!['VERIFIED', 'REJECTED'].includes(var_statuspopup_value)) {
            errors.push({ property: 'updatestatus', description: t('Select Approve or Reject') })
        }
        set_errors(errors);
        if (errors.length > 0) return;

        update_status();
    }

    function onChange_activity() {
        set_cmp_credit_activity_open(false);
        onMessage && onMessage('SUCCESS', t('The credit activity has been successfully updated'));
        onChange && onChange(var_status_badge_button?.id);
    }

    function onClick_status() {
        set_focuselement(document.activeElement);
    }

    function onClick_update_activity() {
        onMessage && onMessage(null, null);
        onClose();
        set_cmp_credit_activity_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <Popup
                trigger={
                    <Button id={`btn_${POPUP_ID}`} onClick={onClick_status} type='button' tabIndex='0' className={'badge text--sm-medium  ' +
                        (   activity.status === 'REJECTED' ?
                                'badge--red'
                            : activity.status === 'VERIFIED' ?
                                'badge--green'
                            : activity.status === 'PENDING' ?
                                'badge--yellow'
                            : 'badge--gray')}
                    >
                        {activity.status_display}
                        {can_edit && <Icon name='badge_dropdown' className='badge__icon'/>}
                    </Button>
                }
                content={
                    is_open ?
                        <div id={POPUP_ID} className='badge__popup shadow-lg rounded-md' aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`} >
                            <div id={`hdr_${POPUP_ID}`} className='badge__popup__title text--md-medium' aria-label={t('Update activity status')} >
                                {t('Change status')}
                            </div>
                            <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`} >

                                <div className='badge__popup__option'>
                                    <CHECKBOX
                                        radio
                                        property='updatestatus'
                                        label={t('Approve')}
                                        value='VERIFIED'
                                        checked={var_statuspopup_value === 'VERIFIED'}
                                        onChange={() => onChange_status('VERIFIED')}
                                        errors={var_errors}
                                    />
                                </div>

                                <div className='badge__popup__option'>
                                    <CHECKBOX
                                        radio
                                        property='updatestatus'
                                        label={t('Reject')}
                                        value='REJECTED'
                                        checked={var_statuspopup_value === 'REJECTED'}
                                        onChange={() => onChange_status('REJECTED')}
                                        errors={var_errors}
                                    />
                                </div>

                                <div className='badge__popup__option'>
                                    <Button type='button' className='tertiary' onClick={onClick_update_activity}>{t('Update credit')}</Button>
                                </div>

                                <div className='badge__popup__option'>
                                    <Button type='button' className='badge__popup__button secondary' onClick={onClick_update_status}>{t('Save')}</Button>
                                </div>
                            </Form>

                            <Processing display={var_processing} processingtext='' />
                        </div>
                        : null
                }
                on='click'
                open={is_open}
                onOpen={() => onOpen(activity.id)}
                onClose={onClose}
                position='bottom left'
            />

            <CMP_CREDIT_ACTIVITY
                is_open={var_cmp_credit_activity_open}
                onClose={() => { set_cmp_credit_activity_open(false); var_status_badge_button.focus(); }}
                activity_id={activity.id}
                onChange={onChange_activity}
                holder_name={activity.holder}
                can_edit={true}
            />
        </>

    );
}

export default CMP_CREDIT_ACTIVITY_STATUS;