import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';
import { Button, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';



export default function CRD_ERROR({ qr_data }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const { t } = useTranslation('public');

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_dashboard() {
        navigate('/dashboard')
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='page' id='qr_code'>
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('QR Code')}</h1>
                </div>

                <div className='card rounded-lg shadow-sm' id='crd__error' tabIndex='0'>
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={qr_data.error === 'YES'
                            ? t('An unexpected error occurred. Please try again.')
                            : qr_data.bad_request === 'YES'
                            ? t('You have scanned an invalid QR code.')
                            : qr_data.is_active === 'NO'
                            ? t('The QR code you scanned is no longer active.')
                            : qr_data.is_expired === 'YES'
                            ? t('The QR code you scanned has expired.')
                            : qr_data.single_use_was_used === 'YES'
                            ? t('The QR code has already been used.')
                            : t('An unexpected error occurred. Please try again.')
                        }
                    />

                    <Button className='primary' onClick={onClick_dashboard} style={{ maxWidth: '12rem' }}>{t('Dashboard')}</Button>
                </div>
            </div>
          </div>
    );

};
