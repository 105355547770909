import React, { useEffect, useState } from 'react';
import { Button, Popup, Form } from 'semantic-ui-react';
import 'i18n';

export default function CMP_POPUP_BUTTON_OPTIONS({ trigger_button_label, popup_title, target_id, is_open, onClose, onOpen, button_options }) {


    //  variable declarations ------------------------------------------------------------------------------------------
    const [ var_popup, set_popup ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);


    const POPUP_ID = (`mdl_popup_button_options_${target_id}`);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_popup(document.querySelector(`#${POPUP_ID}`));
        } else if (var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <Popup
            trigger={
                <Button id='btn_update_popup' className='tertiary' onClick={() => set_focuselement(document.activeElement)} type='button'>
                    {trigger_button_label}
                </Button>
            }
            content={
                is_open ?
                    <div className='badge__popup shadow-lg rounded-md' id={POPUP_ID} aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`}>
                        <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={popup_title}>{popup_title}</div>
                            <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                                {button_options.map(option =>
                                    <div key={option.label} className='badge__popup__option'>
                                        <Button type='button' className='badge__popup__button tertiary' onClick={option.onClick}>{option.label}</Button>
                                    </div>
                                )}
                            </Form>
                        </div>
                    : null
            }
            on='click'
            open={is_open}
            onOpen={onOpen}
            onClose={onClose}
            hideOnScroll
            position='bottom left'
        />
    );
}