import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Message, Form, Button } from 'semantic-ui-react';
import { COLORPICKER, SELECT, INPUT, TOGGLE } from 'components/cmp_form/cmp_form';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import auth from 'libs/auth-lib';
import Icon from 'components/cmp_icon';
import CMP_VERIFICATION_TEMPLATE from 'components/cmp_verification_template/cmp_verification_template';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import Processing from 'components/cmp_processing';
import 'i18n';

import './crd_details.css';

import CMP_FILEUPLOAD from 'components/cmp_fileupload/cmp_fileupload';

export default function CRD_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_new_theme, set_new_theme ] = useState({});
    const [ var_default_theme, set_default_theme ] = useState({});
    const [ var_confirmation_open, set_confirmation_open ] = useState(false);
    const [ var_logo_options, set_logo_options ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_logo_toggle, set_logo_toggle ] = useState('ORGANIZATION');
    const [ var_fileupload_status, set_fileupload_status] = useState(null);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_local_file, set_local_file ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_logo_options();
        populate_theme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function update_fileupload() {
            if (var_fileupload_status === 'UPLOAD COMPLETE') {
                let theme_id = await API_post_verification_template_theme();
                navigate(`/org/verifications/themes/${theme_id}`);
            } else if (var_fileupload_status === 'UPLOAD FAIL') {
                set_save_error(t('There was a problem saving.  Please try again later'));
            }
        }
        update_fileupload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_theme() {
        try {
            let results = await API_get_verification_template_default_theme();
            if (results.is_first_template !== 'YES') {
                results.name = '';
            }
            set_default_theme(results);
            set_new_theme(results);
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }

    async function populate_logo_options() {
        try {
            let results = await API_get_org_logos();
            let transformed = results.filter(item => item.scan_status === 'CLEAN').map(item => ({
                value: item.logo,
                text: item.submittedfilename
            }));
            set_logo_options(transformed);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_logos() {
        return API.get('org-profile', '/get-org-logos');
    }

    function API_post_verification_template_theme() {
        let body = {
            name: var_new_theme.name,
            logo: var_new_theme.logo,
            scan_status: var_new_theme.scan_status,
            background_color: var_new_theme.background_color,
            text_color: var_new_theme.text_color,
            filename: var_new_theme.filename
        }
        return API.post('verifiable-credentials', '/post-verification-template-theme', { body });
    }

    function API_get_verification_template_default_theme() {
        return API.get('verifiable-credentials', '/get-verification-template-default-theme');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_design(e, { name, value }) {
        set_message_text(null);
        set_save_error(null);
        let new_theme = { ...var_new_theme };
        if (name === 'logo') {
            new_theme.logo = value;
            new_theme.logo_classification = 'logo';
        } else {
            new_theme[name] = value;
        }
        set_errors(var_errors.filter(error => error.property !== name));
        set_new_theme(new_theme);
    }

    async function onClick_save() {
        if (var_processing) return;
        set_message_text(null);
        set_save_error(null);
        let updated_design = {...var_new_theme};
        let errors = [];
        //validation
        if (!form_helper.validate_required_string(updated_design.name)) {
            errors.push({ property: 'name', description: t('Please add a name.') });
        }
        set_errors(errors);
        if (errors.length > 0) return;
        // cleanse properties
        form_helper.cleanse_string_property(updated_design, 'name');

        set_processing(true);

        try {
            if (var_fileupload_status === 'UPLOAD READY') {
                set_fileupload_status('START UPLOAD');
            } else {
                let theme_id = await API_post_verification_template_theme();
                navigate(`/org/verifications/themes/${theme_id}`);
            }
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }


    function onClick_cancel() {
        navigate(`/org/verifications?tab=themes`);
    }

    function onClick_restore() {
        set_save_error(null);
        set_message_text(null);
        set_confirmation_open(true);
    }

    function onConfirm_restore() {
        set_new_theme({ ...var_default_theme });
        set_confirmation_open(false);
    }

    function onClick_profile(event) {
        event.preventDefault();
        navigate('/org/profile');
    }

    function onClick_logo_toggle(value) {
        set_logo_toggle(value);
        if (value === 'ORGANIZATION') {
            set_local_file([]);
        }
    }

    function onChange_upload_details(details) {
        set_new_theme({...var_new_theme, filename: details.filename, submittedfilename: details.display_name});
    }

    function onChange_upload(file) {
        set_local_file(file);
    }

    function onDelete_file() {
        set_local_file([]);
    }

    // RENDER APP ======================================================================================================

    return (!auth.has_orgaccess('ORG-VERIFICATIONTEMPLATES-ADMIN', 'write') ? null :
        <div className='card rounded-lg shadow-sm' id='crd_theme_design' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_designer' className='text--xl-medium'>{t('Theme details')}</div>
                    <div className='text--sm-regular'>{t('Customize the verification template theme with your company logo and brand colours.')}</div>
                </div>
            </div>

            <Form className='card__content' id='form_designer' aria-labelledby='hdr_designer'>

                <div className='crd_body template'>

                    <div className='crd_form'>
                        {render_template_form()}
                    </div>

                    {!!var_default_theme.id ?
                        <CMP_VERIFICATION_TEMPLATE
                            display_type='inline'
                                template={{
                                issuer_id: var_new_theme.issuer_id,
                                logo: var_new_theme.logo,
                                title: t('Lorem ipsum dolor sit amet, consectetur.'),
                                description: t('Praesent dignissim purus lectus, vitae sodales velit posue re at. Nunc imperdiet tortor a magna eleifend, a congue.'),
                                background_color: var_new_theme.background_color,
                                text_color: var_new_theme.text_color,
                                logo_classification: var_new_theme.logo_classification
                            }}
                            local_file={var_local_file[0]}
                        />
                        : null
                    }
                </div>
            </Form>

            <CMP_CONFIRMATION
                display={var_confirmation_open}
                title={t('Restore default theme design')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('This action will undo your customizations and revert the theme to the default design.')}</div>
                        <div>{t('This action can not be undone.')}</div>
                    </div>}
                positive_option={t('Restore default')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_restore}
                onCancel={() => set_confirmation_open(false)}
            />

            <Processing display={var_processing} processingtext={t('Processing')} />
        </div>
    );

    function render_template_form() {
        return (
            <>
                {var_save_error &&
                    <Message error header={var_save_error} />
                }
                {!!var_message_text &&
                    <Message error={true}
                    icon={<Icon name='error' className='icon' />}
                    header={var_message_text} />
                }

                <div className='inputs_row' style={{ flexDirection: 'column' }}>
                    <div className='detailsgroup__label text--sm-medium'>{t('Name')}</div>
                    <INPUT
                        property='name'
                        label={t('Name')}
                        value={var_new_theme.name}
                        onChange={onChange_design}
                        placeholder={t('Name')}
                        maxLength={100}
                        disabled={var_new_theme.is_first_template === 'YES'}
                        errors={var_errors}
                    />
                </div>

                <div className='inputs_row' style={{ flexDirection: 'column' }}>
                    <div className='detailsgroup__label text--sm-medium'>{t('Logo')}</div>
                    <div className='text--xs-regular logo__description'>{t('Choose the logo you want in the template. Select an existing logo for your organization or upload a new one if creating a template for another organization.')}</div>
                    <TOGGLE value={var_logo_toggle} options={[{value: 'ORGANIZATION', text: t('Organization logo')}, {value: 'UPLOAD', text: t('Upload logo')}]} onChange={onClick_logo_toggle} />
                    {var_logo_toggle === 'ORGANIZATION' ?
                        <>
                            <SELECT
                                property='logo'
                                value={var_new_theme.logo || ''}
                                onChange={onChange_design}
                                placeholder={t('Select logo')}
                                options={var_logo_options}
                            />
                            <div className='org_profile_link_wrapper text--xs-regular'>{t('Go to your profile to')} <a href='/' onClick={onClick_profile}>{t('edit or add additional logos')}</a> {t('for your organization.')}</div>
                        </>
                        :
                        <CMP_FILEUPLOAD
                            var_status={var_fileupload_status}
                            set_status={set_fileupload_status}
                            onChange_details={onChange_upload_details}
                            onChange_upload={onChange_upload}
                            onDelete={onDelete_file}
                            single_or_multiple='SINGLE'
                            uploaddirectory='logo' max_size={10} allowed_fileextensions={['png', 'jpg', 'jpeg']}
                            instructions={t('Only JPG, and PNG file types are accepted.')}
                        />
                    }
                </div>
                <div className='inputs_row color_picker_wrapper'>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_background_color'>{t('Background color')}</label></div>
                        <COLORPICKER property='background_color' value={var_new_theme.background_color ?? '#ffffff'} onChange={onChange_design} id='cp_background_color' />
                    </div>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_text_color'>{t('Text color')}</label></div>
                        <COLORPICKER property='text_color' value={var_new_theme.text_color ?? '#ffffff'} onChange={onChange_design} id='cp_text_color' />
                    </div>
                </div>
                <div className='spacer'></div>

                <div className='card__header__left footer__btns'>
                    <Button className='primary' type='button' onClick={onClick_save}>{t('Save theme')}</Button>
                    <Button className='secondary' type='button' onClick={onClick_cancel}>{t('Cancel')}</Button>
                </div>

                <Button className='tertiary' onClick={onClick_restore}>
                    {t('Restore default design')}
                </Button>
            </>
        );
    }
}
