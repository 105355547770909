import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import TABS from 'components/cmp_tabs/cmp_tabs';
import auth from 'libs/auth-lib';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TAB_CREDENTIALS from './tab_credentials/tab_credentials';
import TAB_VERIFICATIONS from './tab_verifications/tab_verifications';
import TAB_PENDING_CREDIT_ACTIVITY from './tab_pending_credit_activity/tab_pending_credit_activity';
import TAB_HOLDERS from './tab_holders/tab_holders';
import TAB_DESIGN from './tab_design/tab_design';



export default function CREDENTIALS({ tab }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_tab, set_tab ] = useState('CATALOGUE');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (tab) {
            set_tab(tab.toUpperCase());
        } else {
            set_tab('CATALOGUE');
        }
    }, [tab]);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }]}
                current_page_text={t('Credentials')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('Credentials')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Credentials')} id='tabs_credentials'>
                    <TABS.TAB name='CATALOGUE' label={t('Catalogue')} />
                    <TABS.TAB name='VERIFICATIONS' label={t('Verifications')} display={auth.has_orgaccess('ORG-ISSUER-ADMIN', 'verify')} />
                    <TABS.TAB name='PENDINGCREDITS' label={t('Pending credits')} display={auth.has_orgaccess('ORG-CREDITTRACKING-ADMIN', 'write')} />
                    <TABS.TAB name='HOLDERS' label={t('Holders')} />
                    <TABS.TAB name='DESIGN' label={t('Design')} />
                </TABS>

                {var_tab === 'CATALOGUE' &&
                    <TAB_CREDENTIALS />
                }

                {var_tab === 'VERIFICATIONS' &&
                    <TAB_VERIFICATIONS />
                }

                {var_tab === 'PENDINGCREDITS' &&
                    <TAB_PENDING_CREDIT_ACTIVITY />
                }

                {var_tab === 'HOLDERS' &&
                    <TAB_HOLDERS activetab={var_tab} />
                }

                {var_tab === 'DESIGN' &&
                    <TAB_DESIGN />
                }
            </div>
        </div>
    )
};