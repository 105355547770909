import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';

import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';

import './login.css';


export default function LOGIN(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_display_error, set_display_error ] = useState(false);
    const [ var_error_message, set_error_message ] = useState(null);

    let browser_language = window.navigator.language.split('-')[0];


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        handle_load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function handle_load() {
        let options = {};
        // Pull values from URL
        let params = new URLSearchParams(window.location.search);
        if (params.has('redirect')) {
            window.location.assign(params.get('redirect'));
            return;
        } else if (window.location.pathname.length > 7) { // length of "/login/"")
            // deep link, save deep link for use later
            // Note, does not support querystring or hash
            options.deep_link = window.location.pathname.substring(6);
        } else if (params.has('location_id')) {
            let qrdata = await API_get_qrdata(params.get('location_id'));
            if (qrdata.length === 1) {
                options.qrdata = qrdata[0];
                options.qrdata.location_id = params.get('location_id');
                options.qrdata.logo_path = await API.endpoint('storage') + '/logo/' + qrdata[0].organization_id + '/' + qrdata[0].logo;
                auth.is_loggedin && auth.logout();
            }
        } else if (!!sessionStorage.getItem('invitestatus')) {
            options.invite = JSON.parse(sessionStorage.getItem('invitestatus'));
        }
        if (params.has('sso')) {
            options.sso = params.get('sso');
        }
        auth.iam.login(options, login_callback, onSessionStorageError);
    }

    async function login_callback(options) {
        let result;
        try {
            if (!!options.qrdata) {
                result = await API_put_qrcode_accept(options.qrdata.location_id);
            } else if (!!options.invite) {
                result = await API_put_invite_accept(options.invite.inviteid);
            } else if (options.new_login) {
                result = await API_put_login(options);
            }

            if (result?.post_logout_redirect_uri) {
                sessionStorage.setItem('post_logout_redirect_uri', result.post_logout_redirect_uri);
            }
            result = result?.result;
        } catch (exception) {
            if (exception.response?.status === 403) {
                result = exception.response?.data?.message ?? 'ERROR';
            } else {
                console.log(exception);
                result = 'ERROR';
            }
        }

        if (['NO ACTIVE ACCOUNT', 'ERROR'].includes(result)) {
            await auth.iam.logout();
            set_error_message(t('An unexpected error occurred. Please try again.'));
            set_display_error(true);
        } else if (result === 'INVALID OPTIONS') {
            set_error_message(t('An unexpected error occurred. Please try again.'));
            set_display_error(true);
        } else if (result === 'NO GUEST') {
            set_error_message(t('This organization does not allow guest users.'));
            set_display_error(true);
        } else {
            auth.clear();
            auth.set_authentication({ loggedin: true });
            if (!!options?.deep_link) {
                sessionStorage.setItem('deep_link', options.deep_link);
            }
            if (result === 'NEW USER') {
                sessionStorage.setItem('onboarding', true);
            }
            appProps.set_userstatus('LOGGEDIN');
        }
    }

    async function onSessionStorageError() {
        set_error_message(t('Your browser is preventing Credivera from functioning properly.  Please disable any privacy protections or extensions and try again.'));
        set_display_error(true);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_qrdata(location_id) {
        return API.get('admin', '/get-qrdata?location_id=' + location_id);
    }

    function API_put_invite_accept(token) {
        return API.put('admin', '/put-invite-accept/' + token, {
            body: {
                default_language: browser_language,
                tz: datelib.tz
            }
        });
    }

    function API_put_login(options) {
        return API.put('admin', '/put-login', {
            body: {
                options,
                default_language: browser_language,
                tz: datelib.tz
            }
        });
    }

    function API_put_qrcode_accept(location_id) {
        return API.put('admin', '/put-qrcode-accept/' + location_id, {
            body: {
                default_language: browser_language,
                tz: datelib.tz
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return var_display_error
        ?
            <div className='login_wrapper'>
                <div id='content' tabIndex='0'>
                    <div className='card rounded-lg shadow-sm'>
                        <div className='logo icon__error_lg'>
                            <Icon name='error'/>
                        </div>
                        <div className='display--xs-medium'>
                            {var_error_message}
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='login_wrapper'>
                <div id='content' tabIndex='0'>
                    <div className='login'>
                        <Processing display={true} processingtext={t('Processing')} />
                    </div>
                </div>
            </div>
        ;
}