import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Processing from 'components/cmp_processing';

import CRD_ERROR from './crd_error/crd_error';
import CRD_AUTO_ADD_CREDENTIAL_CREDIT_TRACKING from 'containers/qr/crd_auto_add_credential_credit_tracking/crd_auto_add_credential_credit_tracking';


export default function QR() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const params = useParams();
    const { t } = useTranslation('public');

    const [ var_qr_data, set_qr_data ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        populate_qr_code();
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_qr_code() {
        set_qr_data(await API_get_qr_code());
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_qr_code() {
        return API.get('admin', '/get-qr-code/' + params.qr_code_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return var_qr_data === null
        ? <div className='page' id='qr_code'>
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('QR Code')}</h1>
                </div>

                <div className='card rounded-lg shadow-sm' id='crd__qr_code' tabIndex='0' style={{ minHeight: '15rem' }}>
                    <Processing display={true} processingtext={t('Processing')} />
                </div>
            </div>
          </div>
        : (var_qr_data.invalid === 'YES')
        ? <CRD_ERROR qr_data={var_qr_data} />
        : var_qr_data.qr_code_type === 'AUTO ADD CREDENTIAL CREDIT TRACKING'
        ? <CRD_AUTO_ADD_CREDENTIAL_CREDIT_TRACKING qr_data={var_qr_data} />
        : <CRD_ERROR qr_data={{ error: 'YES' }} />

};
