import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';

function TEXTAREA({ property, value, onChange, placeholder, maxLength, disabled, errors }) {

    //  event functions ------------------------------------------------------------------------------------------------
    let error = errors.find(item => item.property === property);



    // RENDER ==========================================================================================================
    return (
        <Form.Field>
            <Form.TextArea
                name={property}
                value={value || ''}
                onChange={onChange}
                placeholder={placeholder || ''}
                maxLength={maxLength}
                disabled={disabled}
                error={error !== undefined}
                icon={error && <Icon name='error' className='icon icon__field__error' />}
            />
            {error &&
                <div className='field__feedback error'>
                    <div className='description text--sm-regular'>{error.description}</div>
                </div>
            }
            {maxLength && <div className='textarea_characters text--xs-regular'>{value.length}/{maxLength}</div>}
        </Form.Field>
    );
}

TEXTAREA.propTypes = {
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    placeholder: propTypes.string,
    maxLength: propTypes.number,
};

export default TEXTAREA;