import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';
import lookup from 'libs/lookup-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { CHECKBOX, SELECT, TEXTAREA } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import CMP_NEWVERSION from 'components/cmp_credential_newversion/cmp_credential_newversion';
import CMP_WARNING from 'components/cmp_warning/cmp_warning';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import 'i18n';


function CMP_CREDENTIAL_STATUS({ can_manage, is_open, is_active, can_verify, can_update, credential, onChange, onMessage, onOpen, onClose, update_credential_id, update_issuer_name, update_credential_name, update_holder, assignment_id, has_prequalifier, credits_required_for_issuance }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_statuspopup_value, set_statuspopup_value ] = useState(null);
    const [ var_confirmation, set_confirmation ] = useState(null);
    const [ var_confirmation_text, set_confirmation_text ] = useState(null);
    const [ var_display_reason, set_display_reason ] = useState(false);
    const [ var_reason, set_reason ] = useState(null);
    const [ var_cmp_warning_open, set_cmp_warning_open ] = useState(false);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_updatecredential_open, set_updatecredential_open ] = useState(false);
    const [ var_confirmation_status_options, set_confirmation_status_options ] = useState([]);

    const [ var_processing, set_processing ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_archive_confirmation_message_requirements, set_archive_confirmation_message_requirements ] = useState(null);
    const [ var_archive_confirmation_message_credentials, set_archive_confirmation_message_credentials ] = useState(null);

    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_status_badge_button, set_status_badge_button ] = useState(null);
    const [ var_popup, set_popup ] = useState(null);

    const POPUP_ID = `cmp_credential_status_${credential?.id}`;

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_status_badge_button(document.getElementById(`btn_${POPUP_ID}`));
    }, [POPUP_ID]);

    useEffect(() => {
        if (is_open) {
            set_popup(document.querySelector(`#${POPUP_ID}`));
            set_errors([]);
            if (credential.confirmation_status) {
                set_confirmation(credential.confirmation_status.toUpperCase());
            }
            set_display_reason(false);
            set_reason(null);
            set_statuspopup_value(credential.verification_status);
            populate_confirmation_status_options();
        } else if (!is_open && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('.badge__popup, form, input[type="checkbox"]:not([disabled]), select:not([disabled]), button:not([disabled]), textarea:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup, var_display_reason]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_confirmation_status_options() {
        set_confirmation_status_options(await lookup.confirmation_status.get_select_options({ include_extended_data: true }));
    }

    function get_comfirmation_status_options(verification_status) {
        return var_confirmation_status_options.filter(item => item.verification_status === verification_status);
    }

    async function update_status() {
        set_processing(true);
        onMessage(null, null);
        if (credential.confirmation_status !== var_confirmation) {
            try {
                await API_put_verification(credential.id, var_confirmation, var_reason);
                onChange && onChange(var_focuselement?.id);
            } catch (e) {
                onMessage('ERROR', t('There was a problem saving.  Please try again later'));
                console.log(e);
            }
            onClose();
        }
        set_processing(false);
    }

    async function reissue_stacked_credential() {
        set_processing(true);
        onMessage(null, null);
        try {
            await API_put_reissue_stacked_credential();
            onChange && onChange(var_focuselement?.id);
        } catch (e) {
            onMessage('ERROR', t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        onClose();
        set_processing(false);
    }

    async function check_credential_issuer() {
        try {
            if (await API_get_credential_issuer_status() === 'ACTIVE') {
                onClick_updatecredential();
            } else {
                set_cmp_warning_open(true);
            }
        } catch(e) {
            console.log(e);
        }
    }


    async function update_active_status() {
        set_processing(true);
        try {
            await API_put_archive();
            onMessage('SUCCESS', is_active ? t('The credential has been deactivated.') : t('The credential has been activated.'));
            onChange && onChange(var_focuselement?.id);
        } catch(e) {
            onMessage('ERROR', t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
        onClose();
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_verification(individualcredential_id, confirmation_status, var_reason) {
        return API.put('credentials', '/put-verification/', {
                queryStringParameters: {
                    tz: datelib.timezone
                },
                body: {
                    id: individualcredential_id,
                    status: confirmation_status,
                    comment: var_reason
                },
            }
        );
    }

    function API_get_credential_issuer_status() {
        return API.get('credentials', '/get-credential-issuer-status/' + credential.credential_id);
    }

    function API_put_archive() {
        return API.put('credentials', '/put-archive', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                individualcredential_id: credential.id,
                status: is_active ? 'ARCHIVE' : 'ACTIVE'
            }
        });
    }

    function API_get_usage() {
        return API.get('credentials', '/get-usage/' + credential.id);
    }

    function API_put_reissue_stacked_credential() {
        return API.put('credentials', '/put-reissue-stacked-credential/' + credential.id, {
                queryStringParameters: {
                    tz: datelib.timezone
                }
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_verification(value) {
        set_errors([]);
        set_confirmation(null);
        set_statuspopup_value(value);
    }

    async function onChange_confirmation_invalid(event, { value }) {
        if (event.type === 'keydown' && ['ArrowDown','ArrowUp'].includes(event.key) ) {
            // don't set the confirmation if the options change by keyboard up/down
            return;
        }
        set_errors([]);
        set_confirmation(value);
        set_confirmation_text(await lookup.confirmation_status.get_name(value));
        set_display_reason(true);
    }

    function onChange_confirmation_valid(event, { value }) {
        set_errors([]);
        set_confirmation(value);
    }

    async function onClick_updatestatus() {
        if (var_processing) return;
        // validation
        let errors = [];
        if (!['Valid', 'Invalid'].includes(var_statuspopup_value)) {
            errors.push({ property: 'updateverification', description: t('Select Invalid or Valid') })
        }
        if (!form_helper.validate_required_string(var_confirmation)) {
            errors.push({property: 'confirmation', description: t('Select confirmation status')});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        update_status();
    }

    function onClick_updatecredential() {
        onMessage(null, null);
        onClose();
        set_updatecredential_open(true);
    }

    function onChange_credential() {
        set_updatecredential_open(false);
        onMessage('SUCCESS', t('The credential has been successfully updated'));
        onChange && onChange(var_status_badge_button?.id);
    }

    function onClick_return() {
        set_display_reason(false);
        set_reason(null);
        set_statuspopup_value(null);
        set_confirmation(null);
    }

    async function onClick_update_active_status() {
        if (is_active) {
            // if the credential is valid, check if it is used for any requirements
            let requirements, credentials = null;
            if (credential.verification_status.toLowerCase() === 'valid') {
                // get usage
                let usage = await API_get_usage();
                // get unique requirements that have been accepted
                requirements = [...new Set(usage.filter(item => item.individualrequirement_status === 'VALID').map(item => item.requirement_name))];

                // get stacked credentials where this is used
                credentials = [...new Set(usage.filter(item => item.targetentity_type === 'CREDENTIAL').map(item => item.targetentity_name))].sort();
            }
            if (requirements && requirements.length > 0) {
                set_archive_confirmation_message_requirements(
                    <div style={{ display: 'inline' }}>{credentials?.length > 0 ? t('This action will cause') : t('Additionally, this action will cause')} <span className='text--sm-bold'>{requirements.join(', ')}</span> {t('to become unmet, which could affect the individual\'s ability to work.')}</div>
                );
            }
            if (credentials && credentials.length > 0) {
                set_archive_confirmation_message_credentials(
                    <div style={{ display: 'inline' }}>{t('Additionally, this will make a pre-qualifier for')} <span className='text--sm-bold'>{credentials.join(', ')}</span> {t('to become unmet, resulting in a status change to ‘In-progress’ for the credential.')}</div>
                );
            }
            set_mdl_confirmation_open(true);
            onClose();
        } else {
            await update_active_status();
        }
    }

    function onClick_status() {
        set_focuselement(document.activeElement);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <Popup
                trigger={
                    <Button id={`btn_${POPUP_ID}`} onClick={onClick_status} type='button' tabIndex='0' className={'badge text--sm-medium  ' +
                        (   credential.verification_status.toLowerCase() === 'expired' ?
                                'badge--red'
                            : credential.verification_status.toLowerCase() === 'valid' ?
                                'badge--green'
                            : credential.verification_status.toLowerCase() === 'invalid' ?
                                'badge--red'
                            : (['pending', 'in progress'].includes(credential.verification_status.toLowerCase())) ?
                                'badge--yellow'
                    : 'badge--gray')}>
                        {credential.verification_status_display}
                        {(can_update || can_manage || can_verify) &&
                            <Icon name='badge_dropdown' className='badge__icon'/>
                        }
                    </Button>
                }
                content={
                    is_open ?
                        <div id={POPUP_ID} className='badge__popup shadow-lg rounded-md' aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`} >
                            <div id={`hdr_${POPUP_ID}`} className='badge__popup__title text--md-medium' aria-label={can_verify && credential.verification_status.toLowerCase() !== 'expired' ? t('Update credential status') : t('Update credential details')} >{
                                var_display_reason ? var_confirmation_text
                                : can_verify && credential.verification_status.toLowerCase() !== 'expired' ? t('Change status')
                                : t('Change details')}
                            </div>
                            <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`} >
                                {var_display_reason ?
                                    <div className='badge__popup__suboption'>
                                        <TEXTAREA
                                            property='rejected_comment'
                                            value={var_reason || ''}
                                            onChange={event => set_reason(event.target.value)}
                                            placeholder={t('Reason')}
                                            maxLength={100}
                                            disabled={false}
                                            errors={[]}
                                        />
                                        <Button type='button' className='tertiary' onClick={onClick_return}>{t('Return to options')}</Button>
                                    </div>
                                : <>
                                    {can_verify && credential.verification_status.toLowerCase() !== 'expired' &&
                                    <>
                                        <div className='badge__popup__option'>
                                            <CHECKBOX radio property='updateverification' label={t('Invalid')} value='Invalid' checked={var_statuspopup_value === 'Invalid'} onChange={() => onChange_verification('Invalid')} errors={var_errors}/>
                                            {var_statuspopup_value === 'Invalid' &&
                                                <div className='badge__popup__suboption'>
                                                <div className='text--sm-medium'>{t('Confirmation')}</div>
                                                    <SELECT
                                                        property='confirmation'
                                                        value={var_confirmation || ''}
                                                        onChange={onChange_confirmation_invalid}
                                                        placeholder={t('Select')}
                                                        options={get_comfirmation_status_options('INVALID')}
                                                        use_popup={false}
                                                        errors={var_errors}
                                                        aria_label={t('Choose a confirmation status')}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        {!has_prequalifier && !credits_required_for_issuance &&
                                            <div className='badge__popup__option'>
                                                <CHECKBOX radio property='updateverification' label={t('Valid')} value='Valid' checked={var_statuspopup_value === 'Valid'} onChange={() => onChange_verification('Valid')} errors={var_errors}/>
                                                {var_statuspopup_value === 'Valid' &&
                                                    <div className='badge__popup__suboption'>
                                                        <div className='text--sm-medium'>{t('Confirmation')}</div>
                                                        <SELECT
                                                            property='confirmation'
                                                            value={var_confirmation || ''}
                                                            onChange={onChange_confirmation_valid}
                                                            placeholder={t('Select')}
                                                            options={get_comfirmation_status_options('VALID')}
                                                            use_popup={false}
                                                            errors={var_errors}
                                                            aria_label={t('Choose a confirmation status')}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {((has_prequalifier || credits_required_for_issuance) && credential.verification_status.toLowerCase() === 'invalid') &&
                                            <div className='badge__popup__option'>
                                                <Button type='button' className='tertiary' onClick={reissue_stacked_credential}>{t('Reissue credential')}</Button>
                                            </div>
                                        }
                                    </>
                                    }

                                    {can_update &&
                                        <div className='badge__popup__option'>
                                            <Button type='button' className='tertiary' onClick={check_credential_issuer}>{t('Update additional details')}</Button>
                                        </div>
                                    }

                                    {can_manage &&
                                        <div className='badge__popup__option'>
                                            <Button type='button' className='tertiary' onClick={onClick_update_active_status}>{is_active ? t('Deactivate credential') : t('Activate credential')}</Button>
                                        </div>
                                    }
                                </>
                                }

                                {((can_verify && credential.verification_status.toLowerCase() !== 'expired') || var_display_reason ) &&
                                    <div className='badge__popup__option'>
                                        <Button type='button' className='badge__popup__button secondary' onClick={onClick_updatestatus}>{t('Save')}</Button>
                                    </div>
                                }
                            </Form>

                            <Processing display={var_processing} processingtext='' />
                        </div>
                        : null
                }
                on='click'
                open={is_open}
                onOpen={() => onOpen(credential.id)}
                onClose={onClose}
                position='bottom left'
                disabled={!can_update && !can_manage && !can_verify}
            />

            <CMP_NEWVERSION
                display={var_updatecredential_open}
                onClose={() => { set_updatecredential_open(false); var_status_badge_button.focus(); }}
                id={credential.id}
                onChange={onChange_credential}
                can_verify={!!can_verify}
                is_admin={!!can_update}
                holder={update_holder}
                assignment_id={assignment_id}
            />

            <CMP_WARNING
                display={var_cmp_warning_open}
                title={t('Inactive issuer')}
                message={
                    <div style={ { flexDirection: 'column' } }>
                        <div style={ { marginBottom: '0.5rem' } }>{t('Updates to this credential are not possible because the issuer is no longer active in the system.')}</div>
                        <div>{t('If you need assistance, please contact the issuer directly for support.')}</div>
                    </div>}
                onClose={() => { set_cmp_warning_open(false); var_status_badge_button.focus(); }}
            />

            <CMP_CONFIRMATION
                display={var_mdl_confirmation_open}
                title={t('Deactive credential')}
                message={
                    <div style={ { flexDirection: 'column' } }>
                        <div style={{marginBottom: '0.5rem'}}>
                            {`${t(`Deactivating this credential will cause any organization you have shared it with to lose access.`)} ${(!var_archive_confirmation_message_requirements && !var_archive_confirmation_message_credentials) ? t('This may impact the individual\'s ability to work.') : ''}`}
                        </div>
                        {var_archive_confirmation_message_requirements &&
                            <div style={{marginBottom: '0.5rem'}}>{var_archive_confirmation_message_requirements}</div>
                        }
                        {var_archive_confirmation_message_credentials &&
                            <div style={{marginBottom: '0.5rem'}}>{var_archive_confirmation_message_credentials}</div>
                        }
                        <div>{t('You can re-activate this credential at any time.')}</div>
                    </div>}
                positive_option={t('Deactivate')}
                negative_option={t('Cancel')}
                onConfirm={update_active_status}
                onCancel={() => { set_mdl_confirmation_open(false); var_status_badge_button.focus(); }}
            />

        </>

    );
}

export default CMP_CREDENTIAL_STATUS;