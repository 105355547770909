import React, { useEffect } from 'react';
import CRD_CREDIT_TASKS from './crd_credit_tasks/crd_credit_tasks';



export default function TAB_CREDIT_TASKS({ activetab, credential, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <CRD_CREDIT_TASKS activetab={activetab} credential={credential} onChange={onChange} />
    );
};
