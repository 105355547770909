import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CHECKBOX, INPUT } from 'components/cmp_form/cmp_form';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import form_helper from 'libs/form-lib';
import Processing from 'components/cmp_processing';

import CMP_POPUP_BUTTON_OPTIONS from 'components/cmp_popup_button_options';

import './crd_credit_tasks.css';



function CRD_CREDIT_TASKS({ activetab, credential, onChange }) {

//  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_credit_tasks, set_credit_tasks ] = useState([]);
    const [ var_edit_credit_tasks, set_edit_credit_tasks ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_open_menu_item, set_open_menu_item ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'prequalifier_type_text' });

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'CREDIT_TASKS' && credential.id) {
            set_message_text(null);
            set_message_type(null);
            set_mode('VIEW');
            set_errors([]);
            set_open_menu_item(null);
            populate_credit_tasks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, credential.id]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credit_tasks() {
        let credit_tasks = await API_get_credential_credit_list();
        // re-arrange results to be a hierarchy
        let rearranged = credit_tasks.filter(item => item.parent_credential_credit_id === null);
        rearranged.forEach(parent => { parent.activities = credit_tasks.filter(item => item.parent_credential_credit_id === parent.id); });
        // add flags to all records
        for (let parent of rearranged) {
            parent.is_new = false;
            parent.is_deleted = false;
            for (let child of parent.activities) {
                child.is_new = false;
                child.is_deleted = false;
            }
        }
        set_credit_tasks(rearranged);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_credit_list() {
        return API.get('credentials', '/get-credential-credit-list/' + credential.id);
    }

    function API_put_credential_credit_list(credit_list) {
        return API.put('credentials', '/put-credential-credit-list/' + credential.id, {
            body: { credential_credits: credit_list }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_message_text(null);
        set_message_type(null);
        set_errors([]);
        set_open_menu_item(null);
        if (var_credit_tasks.length === 0) {
            // make sure we always have at least 1 row
            set_edit_credit_tasks([{ id: uuid(), description: '', credits_requried: null, allow_credit_entry: 'YES', activities: [], is_new: true, is_deleted: false }]);
        } else {
            set_edit_credit_tasks(_.cloneDeep(var_credit_tasks));
        }
        set_mode('EDIT');
    }

    function onClick_addChild(id) {
        let credit_tasks = _.cloneDeep(var_edit_credit_tasks);
        let parent = credit_tasks.find(item => item.id === id);
        if (!parent.activities) {
            parent.activities = [];
        }
        parent.activities.push({ id: uuid(), description: '', credits_required: null, allow_credit_entry: 'YES', is_new: true, is_deleted: false });
        set_edit_credit_tasks(credit_tasks);
        set_open_menu_item(null);
    }

    function onClick_addParent(id) {
        let credit_tasks = _.cloneDeep(var_edit_credit_tasks);
        let task_index = credit_tasks.findIndex(item => item.id === id);
        credit_tasks.splice(task_index + 1, 0, { id: uuid(), description: '', credits_required: null, allow_credit_entry: 'YES', activities: [], is_new: true, is_deleted: false });
        set_edit_credit_tasks(credit_tasks);
        set_open_menu_item(null);
    }

    function onClick_deleteItem(parent_id, child_id) {
        let credit_tasks = _.cloneDeep(var_edit_credit_tasks);
        let parent = credit_tasks.find(item => item.id === parent_id);
        if (!!child_id) {
            if (parent.activities.find(child => child.id === child_id).is_new) {
                parent.activities = parent.activities.filter(child => child.id !== child_id);
            } else {
                parent.activities.find(child => child.id === child_id).is_deleted = true;
            }
            if (parent.activities.every(child => child.is_deleted))  {
                parent.allow_credit_entry = 'YES';
            }
        } else {
            if (parent.is_new) {
                credit_tasks = credit_tasks.filter(item => item.id !== parent_id);
            } else {
                parent.is_deleted = true;
                parent.activities = parent.activities.filter(child => !child.is_new);
                parent.activities.forEach(child => child.is_deleted = true);
            }
            if (credit_tasks.every(item => item.is_deleted)) {
                // make sure we always have at least 1 row
                credit_tasks.push({ id: uuid(), description: '', allow_credit_entry: 'YES', activities: [], is_new: true, is_deleted: false });
            }
        }
        set_edit_credit_tasks(credit_tasks);
    }

    function onChange_input(parent_id, child_id, name, value) {
        if (name === 'credits_required') {
            value = form_helper.format_decimal_string(value, 4, 2);
        }
        let credit_tasks = _.cloneDeep(var_edit_credit_tasks);
        let parent = credit_tasks.find(item => item.id === parent_id);
        let task = !!child_id ? parent.activities.find(child => child.id === child_id) : parent;
        task[name] = value;
        set_edit_credit_tasks(credit_tasks);
    }

    async function onClick_save() {
        let credit_tasks = _.cloneDeep(var_edit_credit_tasks);
        // validation
        let errors = [];
        for (let parent of credit_tasks) {
            if (!form_helper.validate_required_string(parent.description)) {
                errors.push({ property: `${parent.id}-description`, description: t('Description is required') });
            } else {
                form_helper.cleanse_string_property(parent, 'description');
            }
            if (!form_helper.validate_number(parent.credits_required, true, 0.01, 9999.99)) {
                errors.push({ property: `${parent.id}-credits_required`, description: ['null', 'undefined', ''].includes(String(parent.credits_required).trim()) ? t('Credits required is required') : t('Credits required is invalid') });
            } else {
                form_helper.cleanse_decimal_property(parent, 'credits_required');
            }
            if (parent.activities) {
                for (let child of parent.activities) {
                    if (!form_helper.validate_required_string(child.description)) {
                        errors.push({ property: `${child.id}-description`, description: t('Description is required') });
                    } else {
                        form_helper.cleanse_string_property(child, 'description');
                    }
                    if (!form_helper.validate_number(child.credits_required, false, 0.01, 9999.99)) {
                        errors.push({ property: `${child.id}-credits_required`, description: t('Credits required is invalid') });
                    } else {
                        form_helper.cleanse_decimal_property(child, 'credits_required');
                    }
                }
            }
        }
        set_errors(errors);
        if (errors.length > 0) return;

        set_processing(true);
        try {
            await API_put_credential_credit_list(credit_tasks);
            set_message_type('SUCCESS');
            set_message_text('The changes have been saved.');
            set_mode('VIEW');
            populate_credit_tasks();
            onChange && onChange();
        } catch {
            set_message_type('ERROR');
            set_message_text('An unexpected error occurred. Please try again.');
        }
        set_processing(false);
    }

    function onClick_cancel() {
        set_message_text(null);
        set_message_type(null);
        set_errors([]);
        set_mode('VIEW');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_creddetails_credit_tasks' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Credit requirements')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        {   // we're not allowing editing of tasks after it has been published, need to determine the implications of doing so
                            (var_mode === 'VIEW' && var_credit_tasks && var_credit_tasks.length === 0)
                            ? <Button id='btn_edit' className='primary' onClick={onClick_edit}>{t('Add tasks')}</Button>
                            : (var_mode === 'VIEW' && var_credit_tasks && credential.status === 'PENDING')
                            ? <Button id='btn_edit' className='primary' onClick={onClick_edit}>{t('Edit tasks')}</Button>
                            : <></>
                        }
                    </div>
                </div>

                <div className='content__description text--sm-regular'>
                    {t('This credential has credit requirements, of which must be met by the holder for the credential to be valid.')}
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} warning={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                {var_mode === 'VIEW' ? render_view_mode() : render_edit_mode()}

                <div className='card__footer--mobilebtns'>
                    {   // we're not allowing editing of tasks after it has been published, need to determine the implications of doing so
                        (var_mode === 'VIEW' && var_credit_tasks && var_credit_tasks.length === 0)
                        ? <Button id='btn_mobile_edit' className='primary' onClick={onClick_edit}>{t('Add tasks')}</Button>
                        : (var_mode === 'VIEW' && var_credit_tasks && credential.status === 'PENDING')
                        ? <Button id='btn_mobile_edit' className='primary' onClick={onClick_edit}>{t('Edit tasks')}</Button>
                        : <></>
                    }
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>
        </>
    );

    function render_view_mode() {
        return (
            <Table id='credit_tasks' loading={false} ready={true} loadingerror={false} lockcolumns={1} refresh={false} totalrows={var_credit_tasks.length}
                populateconfig={var_populateconfig} onChange={() => {}} populatefilterfunction={() => {}} downloadfunction={() => {}} downloadname='none' hide_tablecontrols={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='description'>{t('Description')}</Table.HeaderCell>
                        <Table.HeaderCell field='credits_required'>{t('Credits Required')}</Table.HeaderCell>
                        <Table.HeaderCell field='allow_entry'>{t('Allow Entry')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {render_view_items()}
                </Table.Body>
            </Table>
        );
    }

    function render_view_items() {
        let rows = [];
        var_credit_tasks.filter(parent => !parent.is_deleted).forEach(parent => {
            rows.push(
                <Table.Row key={parent.id} className='view_parent'>
                    <Table.Cell>{parent.description}</Table.Cell>
                    <Table.Cell>{parent.credits_required}</Table.Cell>
                    <Table.Cell>
                        <CHECKBOX checked={parent.allow_credit_entry === 'YES'} disabled={true} />
                    </Table.Cell>
                </Table.Row>);
            parent.activities?.filter(child => !child.is_deleted)?.forEach(child => {
                rows.push(
                    <Table.Row key={child.id} className='view_child'>
                        <Table.Cell>{child.description}</Table.Cell>
                        <Table.Cell>{child.credits_required}</Table.Cell>
                        <Table.Cell>
                            <CHECKBOX checked={child.allow_credit_entry === 'YES'} disabled={true} />
                        </Table.Cell>
                    </Table.Row>
                )
            });
        });
        return rows;
    }

    function render_edit_mode() {
        return (
            <>
                <Table id='credit_tasks' loading={false} ready={true} loadingerror={false} lockcolumns={1} refresh={false} totalrows={var_edit_credit_tasks.length}
                    populateconfig={var_populateconfig} onChange={() => {}} populatefilterfunction={() => {}} downloadfunction={() => {}} downloadname='none' hide_tablecontrols={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='menu_column' />
                            <Table.HeaderCell field='description'>{t('Description')}</Table.HeaderCell>
                            <Table.HeaderCell field='credits_required'>{t('Credits Required')}</Table.HeaderCell>
                            <Table.HeaderCell field='allow_entry'>{t('Allow Entry')}</Table.HeaderCell>
                            <Table.HeaderCell className='menu_column' />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {render_edit_items()}
                    </Table.Body>
                </Table>

                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                </div>
            </>
        );
    }

    function render_edit_items() {
        let rows = [];
        var_edit_credit_tasks.filter(parent => !parent.is_deleted).forEach(parent => {
            rows.push(
                <Table.Row key={parent.id} className='edit_parent'>
                    <Table.Cell>
                        <CMP_POPUP_BUTTON_OPTIONS
                            target_id={parent.id}
                            is_open={parent.id === var_open_menu_item}
                            onClose={() => set_open_menu_item(null)}
                            onOpen={() => set_open_menu_item(parent.id)}
                            trigger_button_label={
                                    <Icon name='hamburger' className='color--primary-500 icon__hamburger' />
                                }
                            popup_title={t('Add')}
                            button_options={[
                                { onClick: () => onClick_addParent(parent.id), label: t('Add Category') },
                                { onClick: () => onClick_addChild(parent.id), label: t('Add task') }
                            ]}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <INPUT property={`${parent.id}-description`}
                            value={parent.description}
                            onChange={(e, { value }) => onChange_input(parent.id, null, 'description', value)}
                            placeholder={t('Description')}
                            maxLength={1000}
                            errors={var_errors}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <INPUT property={`${parent.id}-credits_required`}
                            value={[null, undefined].includes(parent.credits_required) ? '' : parent.credits_required.toString()}
                            onChange={(e, { value }) => onChange_input(parent.id, null, 'credits_required', value)}
                            placeholder={t('Credits Required')}
                            maxLength={1000}
                            errors={var_errors}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <CHECKBOX property={`${parent.id}-allow_credit_entry`}
                            checked={parent.allow_credit_entry === 'YES'}
                            onChange={() => onChange_input(parent.id, null, 'allow_credit_entry', parent.allow_credit_entry === 'YES' ? 'NO' : 'YES')}
                            disabled={!parent.activities || parent.activities.every(child => child.is_deleted)} />
                    </Table.Cell>
                    <Table.Cell className='cell__icon--right text--anchor'>
                        <Button className='tertiary' onClick={() => onClick_deleteItem(parent.id)}>
                            <Icon name='delete' className='color--primary-500' />
                        </Button>
                    </Table.Cell>
                </Table.Row>
            );
            parent.activities?.filter(child => !child.is_deleted)?.forEach(child => {
                rows.push(
                    <Table.Row key={child.id} className='edit_child'>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                            <INPUT property={`${child.id}-description`}
                                value={child.description}
                                onChange={(e, { value }) => onChange_input(parent.id, child.id, 'description', value)}
                                placeholder={t('Description')}
                                maxLength={1000}
                                errors={var_errors}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <INPUT property={`${child.id}-credits_required`}
                                value={[null, undefined].includes(child.credits_required) ? '' : child.credits_required.toString()}
                                onChange={(e, { value }) => onChange_input(parent.id, child.id, 'credits_required', value)}
                                placeholder={t('Credits Required')}
                                maxLength={1000}
                                errors={var_errors}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <CHECKBOX checked={child.allow_credit_entry === 'YES'} disabled={true} />
                        </Table.Cell>
                        <Table.Cell className='cell__icon--right text--anchor'>
                            <Button className='tertiary' onClick={() => onClick_deleteItem(parent.id, child.id)}>
                                <Icon name='delete' className='color--primary-500' />
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                );
            });
        });
        return rows;
    }
}

CRD_CREDIT_TASKS.propTypes = {
    activetab: propTypes.string.isRequired,
    credential: propTypes.object,
    onChange: propTypes.func
};

export default CRD_CREDIT_TASKS;