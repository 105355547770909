import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_CREDIT_ACTIVITY_STATUS from 'components/cmp_credit_activity_status/cmp_credit_activity_status';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';


export default function TAB_PENDING_CREDIT_ACTIVITY() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_pending_credit_activities, set_pending_credit_activities ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'activity_date' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_activity_status_popup_id, set_activity_status_popup_id ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_credit_activities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credit_activities(populateconfig, focuselement_id, message_text = null) {
        set_message_text(message_text);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_pending_credit_activities(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_pending_credit_activities(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);

            if (focuselement_id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${focuselement_id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_pending_credit_activities').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
            }

        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_pending_credit_activities(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_pending_credit_activities(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_pending_credit_activities(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-org-pending-credit-activities',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_credit_activity_status(id) {
        set_message_text(null);
        set_activity_status_popup_id(id);
    }

    function onClose_credit_activity_status() {
        set_activity_status_popup_id(null);
        set_message_text(null);
        set_message_type(null);
    }

    function onChange_credit_activity_status(focuselement_id) {
        set_activity_status_popup_id(null);
        populate_credit_activities(null, focuselement_id);
    }

    // RENDER APP ======================================================================================================

    return (!auth.has_orgaccess('ORG-CREDITTRACKING-ADMIN', 'write') ? null :
        <>
            <div className='card rounded-lg shadow-sm' id='crd_pending_credit_activities' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_pending_credit_activities' className='text--xl-medium'>{t('Pending credits')}</div>
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='credit_activities' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Pending credit activities')} downloadfunction={download_records}
                        onChange={populate_credit_activities}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='activity_date' datatype='date' filtertype='date'>{t('DATE')}</Table.HeaderCell>
                            <Table.HeaderCell field='holder' datatype='text' filtertype='text'>{t('HOLDER')}</Table.HeaderCell>
                            <Table.HeaderCell field='parent_description' datatype='text' filtertype='text'>{t('CATEGORY')}</Table.HeaderCell>
                            <Table.HeaderCell field='description' datatype='text' filtertype='text'>{t('TASK')}</Table.HeaderCell>
                            <Table.HeaderCell field='credit_value' datatype='number' filtertype='number'>{t('CREDITS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_pending_credit_activities.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <CMP_CREDIT_ACTIVITY_STATUS
                                        is_open={item.id === var_activity_status_popup_id}
                                        activity={item}
                                        onChange={onChange_credit_activity_status}
                                        onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                        onClose={onClose_credit_activity_status}
                                        onOpen={onClick_credit_activity_status}
                                        can_edit={true}
                                    />
                                </Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.activity_date)}</Table.Cell>
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.holder}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>{item.parent_description}</Table.Cell>
                                <Table.Cell>{item.description}</Table.Cell>
                                <Table.Cell>{item.credit_value}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
        </>
    )

}